<template>
  <form>
    <b-form-group :label="$t('commune') + ' *'" class="mb-4">

      <b-form-input
        type="text"
        :placeholder="$t('commune')"
        v-model="menageData.commune"
        @input="updateMenageParent('commune', menageData.commune)"
        required
        class="form-input custom-placeholder custom-input"
      ></b-form-input>
    </b-form-group>
    <b-form-group :label="$t('personnes')+ ' *'">
      <b-form-input
        type="number"
        min="0"
        @paste="(event) => onPaste(event, 'menageData.personnes')"
        @keydown="(event) => onKeyDown(event)"
        :placeholder="$t('personnes')"
        v-model="menageData.personnes"
        @input="updateMenageParent('personnes', menageData.personnes)"
        required
        class="form-input custom-placeholder custom-input"
      ></b-form-input>
    </b-form-group>
    <b-form-group :label="$t('revenu')+ ' *'">
      <b-form-input
        type="number"
        min="0"
        @paste="(event) => onPaste(event, 'menageData.revenu')"
        @keydown="(event) => onKeyDownFloat(event)"
        :placeholder="$t('revenu')"
        v-model="menageData.revenu"
        @input="updateMenageParent('revenu', menageData.revenu)"
        required
        class="form-input custom-placeholder custom-input"
      ></b-form-input>
    </b-form-group>
  </form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  preventExceedingLength,
  handlePaste,
  preventExceedingLengthFloat,
} from "@/utils/inputUtils";
export default {
  props: {
    menageParentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menageData: { ...this.menageParentData },
    };
  },
  computed: {
    ...mapGetters(["getdevisMonoFiche", "getOnlineUser", "getMenage"]),
  },
  watch: {
    menageParentData: {
      deep: true,
      handler(newData) {
        this.menageData = { ...newData };
      },
    },
  },
  methods: {
    ...mapActions([]),
    onKeyDown(event) {
      preventExceedingLength(event);
    },
    updateMenageParent(field, value) {
      this.$emit("update-menage", { field, value });
    },
    onKeyDownFloat(event) {
      preventExceedingLengthFloat(event);
    },

    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
  },
};
</script>
